import React from "react";

const Footer = () => {
    return (
        <footer>
            Boulevard de los Italianos 536, B1874DYS,  Wilde, Buenos Aires.
        </footer>
    )
}

export default Footer