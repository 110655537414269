import React from "react";

import './styles.css';

import Footer from './components/Footer/Footer';
import ContactoForm from "./components/ContactoForm/ContactoForm";

const App = () => {

  return (
    <div className='appContainer'>
          <main>
          <div className="home">
            <div className="top-bar"></div>
            <div className="head-logo">
                <img src="img/logo.png" alt="VALAM"/>
                <h1>LÍDER EN VÁLVULAS A DIAFRAGMA</h1>
            </div>
            <ContactoForm />
            <div className="container-productos">
                <h3>NUESTRAS LÍNEAS DE PRODUCTOS</h3>

                <div className="productos-detalle">

                    <div className="detalle3">
                        <div className="detalle">
                            <img src="img/valvulas-automaticas.png" alt="Válvulas automáticas" />
                            <article>
                                Conozca nuestras diferentes opciones para la automatización de válvulas a diafragma.
                            </article>
                        </div>

                        <div className="detalle">
                            <img src="img/valvulas-vertedero.png" alt="Válvulas vertedero" />
                            <article>
                                Un diseño único a prueba de corrosión y erosión, que garantiza el mejor resultado.
                            </article>
                        </div>

                        <div className="detalle">
                            <img src="img/valvulas-sanitarias.png" alt="Válvulas sanitarias" />
                            <article>
                                Un diseño que combina calidad y terminaciones únicas para una mayor hermeticidad, economía y pureza del producto. 
                            </article>
                        </div>
                    </div>
                    
                    <div className="detalle3">
                        <div className="detalle">
                            <img src="img/valvulas-hidrantes.png" alt="Válvulas hidrantes" />
                            <article>
                                Máxima seguridad brindada por materiales robustos que componen un diseño exclusivo. 
                            </article>
                        </div>

                        <div className="detalle">
                            <img src="img/valvulas-paso-recto.png" alt="Válvulas paso recto" />
                            <article>
                                Diseñada con un cuerpo de pasaje total en aplicaciones con fluidos como lodos y fangos. 
                            </article>
                        </div>

                        <div className="detalle">
                            <img src="img/valvulas-clapeta.png" alt="Válvulas clapeta" />
                            <article>
                                Hermeticidad y economía en un diseño único para los servicios generales, corrosivos y abrasivos.
                            </article>
                        </div>
                    </div>
                    

                </div>
            </div>
            
            <div className="container-caracteristicas">
                <h3>CARACTERÍSTICAS</h3>

                <div className="caracteristicas-detalle">

                    <div className="detalle">
                        <img src="img/c1.png" alt="Hermeticidad absoluta" />
                        <article>
                            Hermeticidad absoluta
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/c2.png" alt="Manejo de fluidos con sólidos en suspensión" />
                        <article>
                            Manejo de fluidos con sólidos en suspensión
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/c3.png" alt="Simplicidad" />
                        <article>
                            Simplicidad
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/c4.png" alt="Fácil mantenimiento" />
                        <article>
                            Fácil mantenimiento
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/c5.png" alt="Sin contaminación de los mecanismos con el fluido" />
                        <article>
                            Sin contaminación de los mecanismos con el fluido
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/c6.png" alt="Producto nacional" />
                        <article>
                            Producto nacional
                        </article>
                    </div>

                </div>
            </div>

            <div className="container-industrias">
                <h3>INDUSTRIAS</h3>

                <div className="industrias-detalle">

                    <div className="detalle">
                        <img src="img/i1.png" alt="Minería" />
                        <article>
                            Minería
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/i2.png" alt="Petrolera" />
                        <article>
                        Petrolera
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/i3.png" alt="Siderúrgica" />
                        <article>
                            Siderúrgica
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/i4.png" alt="Farmacéutica" />
                        <article>
                            Farmacéutica
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/i5.png" alt="Sin contaminación de los mecanismos con el fluido" />
                        <article>
                            Alimenticia
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/i6.png" alt="Producto nacional" />
                        <article>
                            Química
                        </article>
                    </div>

                    <div className="detalle">
                        <img src="img/i7.png" alt="y más" />
                        <article>
                            Y más
                        </article>
                    </div>

                </div>
            </div>
        </div>
        </main>
            <Footer />
    </div>
    );
}

export default App;