import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAEqdKXbAjktao9pvGsw0o4ypA_TDA9Mpw",
    authDomain: "valamlanding.firebaseapp.com",
    projectId: "valamlanding",
    storageBucket: "valamlanding.appspot.com",
    messagingSenderId: "444984942691",
    appId: "1:444984942691:web:e1dc27bdf863fb3dab1856"
  };

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app)

